import instanceConfig from "../instanceConfig"

// for a while, we were pursuing a local backend for dev purposes, so this could be set to 'local'
export const mode = process.env.REACT_APP_MODE || 'cloud'
export const cloudAuth = !!(mode === 'cloud' || process.env.REACT_APP_USE_CLOUD_AUTH)
export const stage = instanceConfig.stage

export const ENABLE_SENTRY = process.env.REACT_APP_ENABLE_SENTRY === '1'
export const GIT_HEAD_COMMIT = process.env.REACT_APP_GIT_HEAD_COMMIT_ID || '(REACT_APP_GET_HEAD_COMMIT_ID not set)'
export const VERSION = process.env.REACT_APP_VERSION  || '(REACT_APP_VERSION not set)'
