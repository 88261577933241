/* eslint-disable */
import 'react-app-polyfill/ie11'
import 'reflect-metadata'
// courtesy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (search, pos) {
      return this.substring(!pos || pos < 0 ? 0 : +pos, pos + search.length) === search
    },
  })
}
// courtesy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length
    }
    return this.substring(this_len - search.length, this_len) === search
  }
}
// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0 //truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (this.length >= targetLength) {
      return String(this)
    } else {
      targetLength = targetLength - this.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this)
    }
  }
}
// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd
if (!String.prototype.padEnd) {
  String.prototype.padEnd = function padEnd(targetLength, padString) {
    targetLength = targetLength >> 0 //floor if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (this.length > targetLength) {
      return String(this)
    } else {
      targetLength = targetLength - this.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
      }
      return String(this) + padString.slice(0, targetLength)
    }
  }
}
// courtesy https://raw.githubusercontent.com/JamesMGreene/Function.name/master/Function.name.js
;(function () {
  var fnNameMatchRegex = /^\s*function(?:\s|\s*\/\*.*\*\/\s*)+([^\(\s\/]*)\s*/

  function _name() {
    var match, name
    if (this === Function || this === Function.prototype.constructor) {
      name = 'Function'
    } else if (this !== Function.prototype) {
      match = ('' + this).match(fnNameMatchRegex)
      name = match && match[1]
    }
    return name || ''
  }

  // Inspect the polyfill-ability of this browser
  var needsPolyfill = !('name' in Function.prototype && 'name' in function x() {})
  var canDefineProp =
    typeof Object.defineProperty === 'function' &&
    (function () {
      var result
      try {
        Object.defineProperty(Function.prototype, '_xyz', {
          get: function () {
            return 'blah'
          },
          configurable: true,
        })
        result = Function.prototype._xyz === 'blah'
        delete Function.prototype._xyz
      } catch (e) {
        result = false
      }
      return result
    })()
  var canDefineGetter =
    typeof Object.prototype.__defineGetter__ === 'function' &&
    (function () {
      var result
      try {
        Function.prototype.__defineGetter__('_abc', function () {
          return 'foo'
        })
        result = Function.prototype._abc === 'foo'
        delete Function.prototype._abc
      } catch (e) {
        result = false
      }
      return result
    })()

  // Add the "private" property for testing, even if the real property can be polyfilled
  Function.prototype._name = _name

  // Polyfill it!
  // For:
  //  * IE >=9 <12
  //  * Chrome <33
  if (needsPolyfill) {
    // For:
    //  * IE >=9 <12
    //  * Chrome >=5 <33
    if (canDefineProp) {
      Object.defineProperty(Function.prototype, 'name', {
        get: function () {
          var name = _name.call(this)

          // Since named function definitions have immutable names, also memoize the
          // output by defining the `name` property directly on this Function
          // instance so that this polyfill will not need to be invoked again
          if (this !== Function.prototype) {
            Object.defineProperty(this, 'name', {
              value: name,
              configurable: true,
            })
          }

          return name
        },
        configurable: true,
      })
    }
    // For:
    //  * Chrome <5
    else if (canDefineGetter) {
      // NOTE:
      // The snippet:
      //
      //     x.__defineGetter__('y', z);
      //
      // ...is essentially equivalent to:
      //
      //     Object.defineProperty(x, 'y', {
      //       get: z,
      //       configurable: true,  // <-- key difference #1
      //       enumerable: true     // <-- key difference #2
      //     });
      //
      Function.prototype.__defineGetter__('name', function () {
        var name = _name.call(this)

        // Since named function definitions have immutable names, also memoize the
        // output by defining the `name` property directly on this Function
        // instance so that this polyfill will not need to be invoked again
        if (this !== Function.prototype) {
          this.__defineGetter__('name', function () {
            return name
          })
        }

        return name
      })
    }
  }
})()
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
  Object.keys = (function () {
    'use strict'
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor',
      ],
      dontEnumsLength = dontEnums.length

    return function (obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object')
      }

      var result = [],
        prop,
        i

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop)
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i])
          }
        }
      }
      return result
    }
  })()
}
// courtesy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

// courtesy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      // .length of function is 2
      'use strict'
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object')
      }

      var to = Object(target)

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index]

        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true,
  })
}

// courtesy https://www.npmjs.com/package/object.values
import objectValues from 'object.values'
if (!Object.values) objectValues.shim()

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return undefined.
      return undefined
    },
    configurable: true,
    writable: true,
  })
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function (valueToFind, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If len is 0, return false.
      if (len === 0) {
        return false
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true
        }
        // c. Increase k by 1.
        k++
      }

      // 8. Return false
      return false
    },
  })
}
// courtesy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
Number.isNaN =
  Number.isNaN ||
  function (value) {
    return value !== value
  }
