import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import moment from 'moment'

import { unregister } from './utils/registerServiceWorker'
import { stage, GIT_HEAD_COMMIT, ENABLE_SENTRY } from './utils/env'
import instanceConfig from './instanceConfig'
import { init as initAnalytics } from './analytics'

document.title = instanceConfig.config.appName || 'Portfolio Management'


const enableSentry = stage.startsWith('prod') || ENABLE_SENTRY

if (enableSentry) {
  console.log('[initializing Sentry client]')
  Sentry.init({
    dsn: 'https://26bf779b66f748498514b30079ee37f5@o1201561.ingest.sentry.io/6326375',
    environment: (instanceConfig.config && instanceConfig.config.awsAccount) + ':' + stage,
    release: 'pfm:' + GIT_HEAD_COMMIT,
    beforeSend(event) {
      if (event.user && event.user.sessionStarted) {
        const duration = moment.duration(Date.now() - event.user.sessionStarted)
        event.user.sessionAge =
          duration < 60_000
            ? `${duration.asSeconds()} second(s)`
            : `${duration.hours()} hour(s), ${duration.minutes()} minute(s)`
      }
      return event
    },
    // attachStacktrace: appends a stackTrace for the error and will show the exact line the error was triggered.
    attachStacktrace: true,
  })
}

initAnalytics()

const mobilePathRegex = /^\/m(?:\/|$)/

if (mobilePathRegex.test(window.location.pathname)) {
  import('./client/tablet/App').then(({ default: MobileApp }) => {
    ReactDOM.render(<MobileApp />, document.getElementById('root'))
  })
} else {
  import('./client/App').then(({ default: App }) => {
    ReactDOM.render(<App />, document.getElementById('root'))
  })
}

// for now, we are not using service workers
unregister()
